/* .main-property {
    position: relative;
} */


.bg_img {
    background-image: url(../../assets/Property/4.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 50vh;
    position: relative;
}

.color-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
}

.bg_content h2 {
    color: black;
}

.p-details {
    /* margin-top: -60px; */
    /* position: absolute;
    top: 0; */
    z-index: 99;
}

@media (max-width: 768px) {
    .bg_img {
        min-height: 30vh;
    }

    .bg_content h2 {
        display: none;
    }
}