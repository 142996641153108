.form-group>label {
    bottom: 30px;
    left: 15px;
    position: relative;
    background-color: #F2F6FA;
    padding: 0px 5px 0px 5px;
    font-size: 14px;
    transition: 0.2s;
    pointer-events: none;
    color: rgb(154, 151, 148);


    .form-control:focus~label {
        bottom: 48px;
        z-index: 100;
    }
}

.form-control:valid~label {
    bottom: 48px;
}

.form-control:focus {
    border: 2px solid #2684FF !important;
    box-shadow: 0 0 0 2px #2684FF;
    border-style: solid;
}

.form-control-new>label {
    bottom: 78px;
    z-index: 100;
}

.form-control {
    background-color: #F2F6FA !important;
}

.css-yk16xz-control {
    background-color: #F2F6FA !important;
}

.select2-selection__control {
    background-color: #F2F6FA !important;
}

.background_color_change {
    color: red;
    z-index: 20;
}


.form-group-new>label {
    bottom: 50px;
    left: 15px;
    position: relative;
    background-color: #F2F6FA !important;
    padding: 0px 5px 0px 5px;
    font-size: 14px;
    transition: 0.2s;
    pointer-events: none;
    color: rgb(154, 151, 148);


    /* .form-control:focus~label {
        bottom: 20px;
        z-index: 100;
    } */
}



.form-group-desc>label {
    bottom: 410px;
    left: 30px;
    position: relative;
    background-color: #F2F6FA;
    padding: 0px 5px 0px 5px;
    font-size: 14px;
    transition: 0.2s;
    pointer-events: none;
    color: rgb(154, 151, 148);
}

.form-control-desc:valid~label {
    bottom: 48px;
}

.form-control-desc:focus~label {
    bottom: 400px;
    z-index: 100;
}

.form-group-desc {
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 10px 5px 5px 5px;
}

.placeHolderColor::placeholder {
    color: #000 !important;
    opacity: 1;
}


.form-group-new-summary>label {
    bottom: 67px;
    left: 15px;
    position: relative;
    background-color: #F2F6FA;
    padding: 0px 5px 0px 5px;
    font-size: 14px;
    transition: 0.2s;
    pointer-events: none;
    color: rgb(154, 151, 148);


    /* .form-control:focus~label {
        bottom: 20px;
        z-index: 100;
    } */
}

.form-control-new-summary>label {
    bottom: 100px;
    z-index: 100;
}

.form-control-new-summary:focus~label {
    bottom: 67px;
    z-index: 100;
}

.custom-bg-color-form-field {
    background-color: #F2F6FA !important;
}




.form-group-new-desc>label {
    /* bottom: 47px; */
    left: 35px;
    position: absolute;
    background-color: #F2F6FA;
    padding: 0px 5px 0px 5px;
    font-size: 14px;
    transition: 0.2s;
    pointer-events: none;
    color: rgb(154, 151, 148);


    /* .form-control:focus~label {
        bottom: 20px;
        z-index: 100;
    } */
}

.form-group-new-desc-label {

    left: 30px;
    position: absolute;
    background-color: #F2F6FA;
    font-size: 14px;
    transition: 0.2s;
    pointer-events: none;
    color: rgb(154, 151, 148);

}

.form-control-new-desc>label {
    bottom: 0px;
    z-index: 100;
}

/* .form-control-new-desc:focus~label {
    bottom: 67px;
    z-index: 100;
} */


.modal {
    --bs-modal-border-color: "1px solid red !important"
}

.custom_dashboard_div_style:hover {

    box-shadow:
        inset 0 -3em 3em rgba(116, 123, 182, 0.3),
        0 0 0 2px white,
        0.3em 0.3em 1em rgba(34, 55, 131, 0.6);
    cursor: pointer;
}